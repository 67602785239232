<template>
    <div>
        <h6 class="mb-2">{{$t('student_list')}}</h6>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <b-table striped hover responsive :items="students" :fields="fields">

                </b-table>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <b-button variant="primary" @click="goBack">{{ $t('back') }}</b-button>
            </div>
        </div>
    </div>
</template>
<script>
import StudentService from "@/services/StudentService";
import qs from "qs";

export default {
    components: {

    },
    props: {
        courseId: {
            default: null,
            type: Number
        },
        semesterId: {
            type: Number
        },
        sectionNo: {
            default: 0,
            type: Number
        }
    },
    data() {
        return {
            students: [],
            fields: [
                {
                    key: 'academic_year',
                    label: this.changeText(this.$t('academic_year'))
                },
               {
                    key: 'period',
                    label: this.changeText(this.$t('period'))
                },
                {
                    key: 'course_code',
                    label: this.$t('course_code')
                },
                {
                    key: 'course',
                    label: this.$t('course')
                },
                {
                    key: 'section',
                    label: this.changeText(this.$t('section'))
                },
                {
                    key: 'registration_date',
                    label: this.changeText(this.$t('registration_date'))
                },
                {
                    key: 'registration_hour',
                    label: this.changeText(this.$t('registration_hour'))
                },
                {
                    key: 'program',
                    label: this.$t('program')
                },
                {
                    key: 'program_code',
                    label: this.$t('program_code')
                },
                {
                    key: 'student_number',
                    label: this.$t('student_number')
                },
                {
                    key: 'class',
                    label: this.$t('class')
                },
                {
                    key: 'name',
                    label: this.$t('name')
                },
                {
                    key: 'surname',
                    label: this.$t('surname')
                },
                {
                    key: 'email',
                    label: this.changeText(this.$t('email'))
                },
                {
                    key: 'repetition_status',
                    label: this.changeText(this.$t('repetition_status')),
                    tdClass: 'text-center',
                },
                {
                    key: 'foreign_status',
                    label: this.changeText(this.$t('foreign_status')),
                    tdClass: 'text-center',
                }
            ]
        }
    },
    metaInfo() {
        return {
            title: this.$t("instructor_courses_title")
        }
    },
    methods: {

        getData(){
            let data = {semester_id:this.semesterId, course_id:this.courseId, section:this.sectionNo}

            let config={
                params:data,
                paramsSerializer:(params)=>qs.stringify(params,{encode:false})
            }
            StudentService.getInstructorStudents(config)
                .then(response => {
                response.data.data.forEach(item => {
                    this.students.push({
                        academic_year: item.academic_year,
                        period: item.semester_name,
                        course_code: item.course_code,
                        course: item.course_name,
                        section: item.section_number,
                        registration_date: item.course_registration_date,
                        registration_hour: item.course_registration_hour,
                        program: item.program_name,
                        program_code: item.program_code,
                        student_number: item.student_number,
                        class: item.class+'. '+this.$t('class'),
                        name: item.name,
                        surname: item.surname,
                        email: item.email,
                        repetition_status: item.is_repeat ? 'x' : '',
                        foreign_status: item.is_foreign ? this.$t('yes') : this.$t('no')
                    })
                })
            })
        },

        goBack() {
            this.$emit('back')
        }
    },

    created() {
        this.getData()
    },

    watch: {
        courseId: {
            handler: function (val) {
                this.getData(val)
            }
        },
        semesterId: {
            handler: function (val) {
                this.getData(val)
            }
        },
        sectionNo: {
            handler: function (val) {
                this.getData(val)
            }
        }
    }
};
</script>
